/* for Header with position: fixed*/
.main {
  margin-top: 70px;
}

/** see https://stackoverflow.com/a/49182617/1310733 */
.position-fixed-scrollable {
  /** Or use position-sticky class and override top: 70px !important; */
  position: sticky;
  top: 70px;
  height: calc(100vh - 70px);
  overflow-y: auto;

  /** or d-flex class */
  display: flex;

  /** or flex-column class */
  flex-direction: column;
}

.notification {
  position: absolute;
  /* header has 55px -> +20 = 75 */
  top: 75px;
  right: 20px;
  min-width: 250px;
  z-index: 2000;
}

.action-icon {
  cursor: pointer;
  margin: auto 2px;
}

.action-icon:hover {
  opacity: 0.8;
}

/** SEPARATOR */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ced4da;
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}

.overlay-wrapper {
  position: relative;
  /* NavTab height is 42px so this should be smaller - see discipline detail */
  min-height: 40px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.overlay-opaque {
  background-color: white;
  opacity: 0.5;
}

.invalid-as-warning {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ffc107' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ffc107' stroke='none'/%3e%3c/svg%3e") !important;
  border-color: #ffc107 !important;
}
